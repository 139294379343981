@import "common/variables";

/** Import everything from bootstrap */
@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-grid";
@import "common/utilities";

@import "includes";

header, footer {
	background-color: $header-color;
}

footer {
	@extend .mt-4;
	@extend .mt-xl-5;
	@extend .py-4;
	@extend .py-xl-4;
}

img {
	@extend .img-fluid;
}

.full-width {
	.container {
		max-width: 100%;
	}
}

.breadcrumb {
	display: none;
}

.social-links {
	gap: 1.5rem;
	a {
		@extend .d-block;
	}
}

.section {
	.section-title {
		@extend .mb-4;
		@extend .mb-lg-5;
		@extend .d-flex;
		@extend .align-items-center;
		flex-wrap: wrap;

		h2 {
			@extend .text-info;
			@extend .text-uppercase;
			@extend .font-weight-bold;
			@extend .mr-4;
			white-space: normal;
		}
		&:after {
			content: '';
			@extend .flex-fill;
			height: 1px;
			background-color: $primary;
		}
		.section-title-button {
			margin: 0 1em 0 0;
		}
	}
}

.section-events {
	@extend .py-4;
	@extend .py-lg-5;

	.events-card {
		@extend .d-flex;
		@extend .flex-column;
		@extend .align-items-center;
		@extend .bg-white;
		@extend .border;
		border-color: #DDDDDD;
		@extend .rounded;
		@extend .p-4;
		@extend .mb-4;
		@extend .mb-xl-0;

		a {
			text-decoration: none;
			text-align: center;
			display: block;
			margin: 0;
			padding: 0;
			border: 0;
		}

		img {
			@extend .mb-2;
		}
		h3 {
			@extend .text-info;
			@extend .font-weight-bold;
			font-size: 1rem;
		}
		
	}
}

.section-testimonials {
	@extend .bg-secondary;
	@extend .py-4;
	@extend .py-lg-5;
	.section-title {
		&:after {
			background-color: white;
		}
	}

	.testimonials-item {
		@extend .d-flex;
		@extend .flex-column;
		@extend .align-items-center;
		@extend .mb-4;
		@extend .mb-lg-0;

		.testimonials-card {
			@extend .bg-white;
			@extend .rounded;
			@extend .p-4;
			@extend .p-xl-5;
			@extend .position-relative;
			font-size: 0.875rem;
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: 0;
				height: 0;
				border: 20px solid transparent;
				border-top-color: white;
				border-bottom: 0;
				margin-left: -20px;
				margin-bottom: -20px;
			}
		}
		cite {
			font-style: normal;
			@extend .d-flex;
			@extend .flex-column;
			@extend .text-center;
			@extend .text-white;
			@extend .mt-4;
			@extend .mt-lg-5;
		}

	}
}

.section-news {
	@extend .bg-secondary;
	@extend .py-4;
	@extend .py-lg-5;
	@extend .mt-4;
	@extend .mt-xl-5;
	.section-title {
		&:after {
			background-color: white;
		}
	}

	.news-item {
		@extend .d-flex;
		@extend .flex-column;
		@extend .mb-4;
		@extend .mb-lg-0;
		@extend .text-white;
		@extend .font-weight-normal;

		.news-img {
			aspect-ratio: 3/2;
			@extend .mb-4;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		.news-meta {
			@extend .font-weight-bold;
			@extend .text-info;
			@extend .mb-4;
		}

		.news-title {
			@extend .text-white;
			@extend .font-weight-normal;
			font-size: 1.5rem;
		}

	}
}

.page-body {
	@extend .my-4;
	@extend .my-lg-5;
}

.page-header {
	@extend .mb-4;
	@extend .mb-lg-5;
	@extend .d-flex;
	@extend .flex-column;
	@extend .flex-lg-row;
	@extend .justify-content-lg-between;
	@extend .align-items-lg-center;
}

.header-info {
	@extend .d-flex;
	@extend .align-items-start;
	@extend .mb-4;
	@extend .mb-lg-0;

	.info-badge {
		@extend .flex-shrink-0;
		width: 45px;
		height: 45px;
		margin-right: 1em;
		@include media-breakpoint-up(md) {
			width: 100px;
			height: 100px;
			margin-right: 1.5em;
		}
	}

	.info-title {
		>* {
			@extend .mb-0;
		}
		.title-h1 {
			font-size: 1.5rem;
			line-height: 1;
			@include media-breakpoint-up(md) {
				font-size: 2rem;
			}
			@include media-breakpoint-up(lg) {
				font-size: 2.5rem;
			}
		}
		.title-sub {
			@extend .text-primary;
			font-size: 1rem;
			@include media-breakpoint-up(md) {
				font-size: 1.25rem;
			}
			@include media-breakpoint-up(lg) {
				font-size: 1.875rem;
			}
			line-height: 1.25;
			&-2 {
				font-size: 1.5rem;
			}
		}
	}
}

.header-details {
	@extend .flex-shrink-0;
	.detail-pills {
		display: grid;
		gap: 1rem;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		@include media-breakpoint-up(md) {
			gap: 1.5rem;
		}

		.pill-item {
			@extend .btn;
			&-lot {
				@extend .btn-secondary;
			}
			&-time {
				@extend .btn-white;
				@extend .btn-icon;
				@extend .btn-icon-clock;
				border: 1px solid #ddd;
				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
			&-reg {
				@extend .btn-warning;
				@extend .rounded;
			}
			&-track {
				@extend .btn-info;
				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
		}
	}
	.detail-list {
		@extend .d-md-none;
		@extend .mt-4;
		@extend .list-unstyled;
		li {
			span, a {
				@extend .d-flex;
				@extend .align-items-center;
			}
			a {
				@extend .text-secondary;
				text-decoration: underline;
			}
		}
		img {
			@extend .mr-1;
		}
	}
}

.body-gallery {
	@extend .mb-4;
	@extend .mb-lg-0;
	.gallery-image {
		@extend .mb-4;
		img {
			@extend .rounded-lg;
			@include media-breakpoint-down(sm) {
				border-radius: 0!important;
			}
		}
	}
	.gallery-items {
		@extend .overflow-hidden;
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .rounded;
		@include media-breakpoint-down(sm) {
			border-radius: 0!important;
		}
		> * {
			flex: 1 1 33.33%;
			@include media-breakpoint-up(md) {
				flex: 1 1 0%;
			}
		}
		.item-arrow {
			@extend .bg-primary;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z' fill='WHITE'/%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: center;
			background-size: auto 1rem;
			cursor: pointer;
		}
	}
	.gallery-more {
		@extend .mt-3;
		@extend .d-flex;
		@extend .flex-wrap;
		row-gap: .75rem;
		column-gap: 1.25rem;
		.more-label {
			@extend .text-uppercase;
		}
	}
}
.details-list {
	@extend .list-unstyled;
	@extend .mb-0;
	.detail-item {
		@extend .d-flex;
		>span {
			@extend .flex-fill;
			@extend .d-flex;
			&:after {
				@extend .flex-grow-1;
				content: '';
				border-bottom: 2px dotted $secondary ;
				margin: 0 .25rem .25rem .25rem
			}
		}
		>strong {
			@extend .text-right;
		}
		&-header {
			@extend .font-weight-bold;
			>span {
				&:after {
					border-bottom: 2px dashed $secondary ;
					margin-bottom: .5rem;
				}
			}
		}
	}
}
.body-content {
	.content-section {
		@extend .rounded;
		@extend .p-4;
		@extend .mb-4;
		@extend .mb-xl-5;

		@include media-breakpoint-up(lg) {
			padding: 2.5rem!important;
		}

		.section-header {
			@extend .d-flex;
			@extend .flex-column;
			@extend .flex-md-row;
			@extend .align-items-md-center;
			@extend .justify-content-md-between;
			margin-bottom: 2rem;
			.header-title {
				@extend .mb-4;
				@extend .mb-lg-0;
				>* {
					@extend .mb-0;
				}
				.title-h1 {
					line-height: 1;
				}
				.title-sub {
					@extend .text-primary;
					font-size: 1.5rem;
					line-height: 1.25;
				}
			}
			.header-cta {
				a {
					@extend .btn;
					@extend .btn-info;
					@extend .btn-icon;
					@extend .btn-icon-right;
					@extend .btn-icon-pdf;
				}
			}
		}
		&-white {
			@extend .bg-white;
			@extend .text-secondary;
			@extend .border;
			border-bottom-width: 3px !important;
			border-color: #ddd;
		}
		&-secondary {
			@extend .bg-secondary;
			@extend .text-white;
		}
		&-details {
			.details-list {
				display: grid;
				column-gap: 1rem;

				@include media-breakpoint-up(xl) {

					grid-template-columns: repeat(2, minmax(0, 1fr));
				}
			}
		}
		&-form {

			.form-group:not(.form-check) {
				label {
					@extend .sr-only;
				}
				input {
					border:0;
				}
			}

			a {
				@extend .text-white;
			}

			button {
				@extend .btn;
				@extend .btn-info;
				@extend .btn-lg;
				@extend .btn-icon;
				@extend .btn-icon-right;
				@extend .btn-icon-login;
				@extend .mt-4;
			}
		}
		&-bid {
			.alert {
				@extend .text-secondary;
			}

			.bid-details {
				@extend .d-flex;
				@extend .flex-column;
				@extend .flex-xl-row;
				@extend .justify-content-xl-between;
				@extend .align-items-xl-end;
				@extend .mt-lg-4;
				.bid-price {
					line-height: 1;
					label {
						display: block;
					}
					strong {
						font-size: 2rem;
					}
					span {
						@extend .text-uppercase;
						@extend .ml-1;
					}
				}
				.bid-time {
					@extend .ml-xl-4;
					strong {
						@extend .text-uppercase;
					}
					img {
						vertical-align: text-top;
					}
				}
			}

			.bid-form {
				@extend .d-flex;
				@extend .flex-column;
				@extend .flex-xl-row;
				@extend .flex-nowrap;
				@extend .align-items-start;
				@extend .align-items-xl-end;
				@extend .justify-content-xl-between;
				gap: 1rem;
				button {
					@extend .flex-shrink-0;
					@extend .btn;
					@extend .btn-info;
					@extend .btn-lg;
					line-height: 1.25;
				}
				.input-group {
					.input-group-text {
						@extend .font-weight-bold;
						font-size: 1.75rem;
						line-height: .8;
					}
					input {
						font-size: 1.25rem;
					}
				}
			}
		}
	}

	.content-register {
		@extend .d-flex;
		@extend .flex-column;

		>span {
			font-size: 1.5rem;
			@extend .text-uppercase;
			@extend .text-info;
			@extend .mb-4;
			@extend .font-weight-bold;
			@extend .text-center;
		}
		>a {
			@extend .btn;
			@extend .btn-lg;
			@extend .btn-info;
			text-transform: none!important;
		}
	}
}

.page-additional {
	>h2 {
		@extend .d-flex;
		@extend .text-info;
		@extend .text-uppercase;
		@extend .align-items-center;
		@extend .mb-4;
		@extend .mb-xl-5;
		&:after {
			content:'';
			height: 1px;
			background-color: $primary;
			margin-left: 1rem;
			flex: 1 1 0%;
		}
	}

	.info-tabs {
		color: $secondary;
		@extend .mb-4;
		@extend .mb-xl-5;
		&-desktop {
			@extend .d-none;
			@extend .d-lg-block;
		}

		.tab-grid {
			display: grid;
			column-gap: 1.5rem;
			row-gap: .5rem;

			@include media-breakpoint-up(md) {
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}

			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(3, minmax(0, 1fr));
			}

			@include media-breakpoint-up(xl) {
				grid-template-columns: repeat(4, minmax(0, 1fr));
			}

			div {
				> strong {
					@extend .text-primary;
					@extend .mb-1;
					@extend .d-block;
				}

				> ul {
					@extend .list-unstyled;
					li {
						@extend .mb-1;
					}
				}
			}
		}

		.tab-columns {
			@extend .row;
			>div {

				>div:not(:last-of-type) {
					@extend .mb-4;
					@extend .mb-xl-5;
				}
				&:first-of-type {
					@include media-breakpoint-up(lg) {
						border-right: 1px solid $primary;
					}
				}
				@extend .col-12;
				@extend .col-lg-6;
				>h3 {
					@extend .h5;
					@extend .mb-4;
				}

				div {
					> strong {
						@extend .text-primary;
						@extend .mb-1;
						@extend .d-block;
					}

					> ul {
						@extend .list-unstyled;
						li {
							@extend .mb-1;
						}
					}

				}
			}
		}


		.condition-gallery {
			/*@extend .d-flex;*/
			gap:1rem;
			.condition-image {
				figcaption {
					@extend .mt-2;
					@extend .font-weight-bold;
				}
			}
			.condition-images {
				/*
				@extend .d-flex;
				@extend .flex-row;
				@extend .flex-lg-column;
				*/
				gap: .5rem;
				a {
					@extend .position-relative;
					span {
						@extend .position-absolute;
						@extend .text-white;
						@extend .font-weight-bold;
						@extend .border;
						@extend .border-white;
						@extend .d-flex;
						@extend .justify-content-center;
						@extend .align-items-center;
						@extend .rounded-circle;
						top:30%;
						right:30%;
						bottom:30%;
						left:30%;
						font-size: 1.25rem;
					}
				}
			}
		}
	}
}

.archive-auction {
	overflow-x: hidden;
}

.auction-header {

	@extend .py-4;
	@extend .py-xl-5;

	h1 {
		@extend .font-weight-normal;
		line-height: 1;
		@extend .text-primary;
		font-size: 2rem;
	}

	.header-details {
		@extend .mt-4;
		@extend .mb-0;
		@extend .list-unstyled;
		@extend .d-flex;
		@extend .flex-column;
		@extend .flex-lg-row;
		gap: 1rem;
		li {
			@extend .d-flex;
			@extend .align-items-center;
			gap: .25rem;
			span, a {
				@extend .d-flex;
				@extend .align-items-center;
			}
			a {
				@extend .text-secondary;
				text-decoration: underline;
			}
			&.detail-download {
				@extend .font-weight-bold;
				@extend .ml-lg-auto;
			}
		}
		img {
			@extend .mr-1;
		}
	}

	&-mobile {
		.header-details {
			gap:0;
			li {
				&.detail-download {
					@extend .mt-3;
				}
			}
		}
	}
	&:not(.auction-header-mobile) {
		@extend .d-none;
		@extend .d-lg-block;
	}
}

.auction-sidebar-col {
	@include media-breakpoint-up(xxl) {
		flex: 0 0 20%;
        max-width: 20%;
	}
	@include media-breakpoint-up(xxxl) {
		flex: 0 0 15%;
        max-width: 15%;
	}
}

.auction-results-col {
	@include media-breakpoint-up(xxl) {
		flex: 0 0 80%;
        max-width: 80%;
	}
	@include media-breakpoint-up(xxxl) {
		flex: 0 0 85%;
        max-width: 85%;
	}
}

.auction-sidebar {
	@extend .position-relative;
	@extend .bg-secondary;
	@extend .pr-lg-4;
	@extend .py-4;
	@extend .py-xl-5;
	@extend .mb-4;
	border-bottom-right-radius: $border-radius-lg;
	.sidebar-bg {
		@extend .position-absolute;
		@extend .bg-secondary;
		top:0;
		right: 100%;
		bottom: 0;
		left: -100%;
	}
	@include media-breakpoint-down(md) {
		border-bottom-right-radius: 0;
		&:after {
			content: '';
			position: absolute;
			background-color: $secondary;
			top:0;
			right: -100%;
			bottom: 0;
			left: 100%;
		}
	}
	a {
		@extend .text-white;
	}
}

.auction-results {
	width: 100%;
	padding-left: 300px;
	float:left;
}

.sidebar-filter {
	@extend .d-flex;
	@extend .flex-wrap;
	@extend .justify-content-between;
	@extend .align-items-center;
	@extend .align-items-lg-stretch;
	@extend .flex-lg-column;
	gap: .5rem;
	.filter-toggle {
		@extend .d-flex;
		@extend .align-items-center;
		@extend .mb-0;
		line-height: 1;
		gap: .5rem;
		@extend .d-lg-none;
		@extend .h2;
		font-weight: 500;
	}
	#filterCollapse {
		@extend .w-100;
		@extend .d-lg-block;
	}
	.filter-fields {
		@extend .d-flex;
		@extend .flex-column;
		gap: 0rem;
		@include media-breakpoint-up(lg) {
			gap: 0rem;
		}

		.form-group {
			/*
			@extend .mb-0;
			@extend .d-flex;
			*/
			gap: 0rem;
			margin-bottom: 6px;
			/*
			input {
				@extend .col;
			}
			@include media-breakpoint-up(lg) {
				gap: 0rem;
			}
			*/
		}
	}
	button {
		@extend .btn;
		&:not([type="submit"]) {
			@extend .btn-white;
			@extend .text-left;
			@extend .text-capitalize;
			span {
				@extend .font-weight-normal
			}
		}
		&[type="submit"] {
			@extend .btn-info;
		}
	}
}

.products-sort {
	@extend .d-flex;
	@extend .align-items-center;
	@extend .mb-4;
	@extend .mb-xl-5;
	font-size: .75rem;
	@include media-breakpoint-up(md) {
		font-size: 1rem;
	}
	.sort-page {
		@extend .d-flex;
		@extend .align-items-center;
		@extend .flex-fill;
		font-weight: 500;

		&:after {
			content: '';

			@extend .mx-4;
			@extend .d-flex;
			@extend .flex-grow-1;
			height: 1px;
			background-color: #ccc;
		}
	}
	.sort-order {
		@extend .text-info;
		@extend .position-relative;

		select {
			appearance: none;
			background-color: transparent;
			border: 0;
			@extend .px-4;
			@extend .position-relative;
			z-index: 20;
			cursor: pointer;
		}

		&:before, &:after {
			content: '';
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			@extend .position-absolute;
			top:0;
			bottom:0;
		}
		&:before {
			left: 0;
			right: auto;
			width: 1rem;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3C!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M183.6 469.6C177.5 476.2 169 480 160 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L128 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 72c0-22.1 17.9-40 40-40H472c22.1 0 40 17.9 40 40V184c0 22.1-17.9 40-40 40H360c-22.1 0-40-17.9-40-40V72zM443.1 303l80 128c6.2 9.9 6.5 22.3 .9 32.5s-16.4 16.5-28 16.5H336c-11.6 0-22.3-6.3-28-16.5s-5.3-22.6 .9-32.5l80-128c5.8-9.4 16.1-15 27.1-15s21.3 5.7 27.1 15z' fill='%23EE7203'/%3E%3C/svg%3E");
		}
		&:after {
			left: auto;
			right: 0;
			width: .75rem;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z' fill='%23EE7203'/%3E%3C/svg%3E");
		}
	}
}

.products-grid {
	position: relative;
	display: grid;
	gap: 1.5rem;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@include media-breakpoint-up(xl) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	@include media-breakpoint-up(xxl) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	@include media-breakpoint-up(xxxl) {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}

	.products-overlay {
		position: absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		z-index: 40;
		background: rgba($light, .75);
		display: none;
		&.show {
			display: block;
		}
	}
}

.products-pagination {
	@extend .mt-4;
}

.news-archive {
	@extend .py-4;
	@extend .py-xl-5;
	h1 {
		@extend .h2;
		@extend .text-info;
		@extend .mb-4;
		@extend .mb-xl-5;
		@extend .d-flex;
		@extend .text-uppercase;
		@extend .align-items-center;
		&:after {
			content:'';
			height: 1px;
			background-color: $primary;
			margin-left: 1rem;
			flex: 1 1 0%;
		}
	}

	.news-grid {
		display: grid;
		gap: 1.5rem;
		grid-template-columns: repeat(1, 1fr);
		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(3, 1fr);
		}
		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(4, 1fr);
		}

		.news-item {
			@extend .bg-danger;
			@extend .text-white;
			@extend .position-relative;
			@include media-breakpoint-down(md) {
				aspect-ratio: 1/1;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
			.item-content {
				@extend .position-absolute;
				top: 33%;
				right:0;
				bottom: 0;
				left:0;
				@extend .p-4;
				@extend .d-flex;
				@extend .flex-column;
				@extend .justify-content-end;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);

				h2 {
					@extend .font-weight-normal;
					font-size: 1rem;
					@extend .text-white;
				}
				.item-meta {
					font-size: 0.875rem;
					@extend .mt-3;
				}
			}
		}

		&-featured {
			@extend .mb-4;
			@extend .pb-4;
			@extend .mb-xl-5;
			@extend .pb-xl-5;
			@extend .border-bottom;
			@extend .border-primary;
			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(3, 1fr);
				grid-template-rows: repeat(4, 1fr);

				.news-item {
					&:nth-of-type(1) {
						grid-area: 1 / 1 / 4 / 4;
					}
					&:nth-of-type(2) {
						grid-area: 4 / 1 / 5 / 2;
					}
					&:nth-of-type(3) {
						grid-area: 4 / 2 / 5 / 3;
					}
					&:nth-of-type(4) {
						grid-area: 4 / 3 / 5 / 4;
					}
				}
			}
			@include media-breakpoint-up(xl) {
				grid-template-columns: repeat(4, 1fr);
				grid-template-rows: repeat(2, 1fr);
				.news-item {
					&:nth-of-type(1) {
						grid-area: 1 / 1 / 3 / 3;
					}
					&:nth-of-type(2) {
						grid-area: 1 / 3 / 2 / 5;
					}
					&:nth-of-type(3) {
						grid-area: 2 / 3 / 3 / 4;
					}
					&:nth-of-type(4) {
						grid-area: 2 / 4 / 3 / 5;
					}
				}
			}
		}
	}

	.news-pagination {
		@extend .mt-4;
	}
}

.news-single {
	@extend .py-4;
	@extend .py-xl-5;
	.news-wrapper {
		@include media-breakpoint-up(xl) {
			width: 83.3333333%;
			margin: 0 auto;
		}
	}
	.news-hero {
		@extend .mb-4;
		@extend .mb-xl-5;
	}
	.news-body {
		@include media-breakpoint-up(lg) {
			border-right: 1px solid $primary;
		}
		@include media-breakpoint-up(xl) {
			border:0;
		}
	}
	.news-divide {
		@extend .d-flex;
		@extend .justify-content-center;
		@include media-breakpoint-up(xl) {
			&:after {
				content: '';
				border-right: 1px solid $primary;
			}
		}
	}
	.body-content {
		h1 {
			@extend .h3;
		}
		h2 {
			@extend .h3;
			@extend .text-primary;
			@extend .mt-4;
			@extend .mt-xl-5;
		}
	}
	.sidebar-links {
		border-top: 1px solid $primary;
		@extend .list-unstyled;
		.sidebar-item {
			border-bottom: 1px solid $primary;
		}
		.sidebar-link {
			@extend .py-4;
			@extend .d-flex;
			@extend .align-items-center;
			@extend .text-secondary;
			font-size: .875rem;
			.link-img {
				@extend .bg-danger;
				width: 20%;
				@extend .flex-shrink-0;
				@extend .mr-4;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
		.sidebar-more a {
			@extend .d-block;
			@extend .text-center;
			@extend .py-4;
			@extend .font-weight-bold;
			@extend .text-uppercase;
			font-size: .875rem;
			 &:after {
				content: '';
				width: 1em;
				height: 1em;
				margin-left: .5rem;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				display: inline-block;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z' fill='%23EE7203'/%3E%3C/svg%3E");
			}
		}
	}
}

.customer-portal {
	@extend .py-4;
	@extend .py-xl-5;
	h1 {
		@extend .h2;
		@extend .text-info;
		@extend .mb-4;
		@extend .mb-xl-5;
		@extend .d-flex;
		@extend .text-uppercase;
		@extend .align-items-center;
		&:after {
			content:'';
			height: 1px;
			background-color: $primary;
			margin-left: 1rem;
			flex: 1 1 0%;
		}
	}
	.portal-section {
		@extend .mb-4;
		@extend .mb-xl-5;
		h2.section-title {
			@extend .h3;
			@extend .text-uppercase;
			@extend .text-center;
			@extend .mb-4;
		}
		.section-grid {
			display: grid;
			gap: 1.5rem;
			grid-template-columns: repeat(1, 1fr);
			@include media-breakpoint-up(md) {
				grid-template-columns: repeat(2, 1fr);
			}
			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}

body {
	.fleet-hide {
		@extend .d-none;
	}
	.fleet-show {
		display: inherit!important;
	}
}