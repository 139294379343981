// Variables

// Fonts
// Alternative to Avenir:
$font-family-sans-serif:      "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-weight-light:           200 !default;
$font-weight-normal:          300 !default;
$font-weight-bold:            600 !default;

// Color system

$header-color: #0D3453;

$primary: 	#4268B0;
$secondary: #0D3453;
$danger: 	#D11335;
$info: 		#EE7203;
$warning: 	#FDCC20;
$success: 	#A1C958;
$light:		#F7F7F7;

$fleet-calendar: #4268B0;
$protruck-calendar: #EE7203;
$calendar-headings: #0D3453;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
  xxxl: 1850px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1800px
);

// Body

$body-bg:		$light;
$body-color:	$secondary;

// Components

$border-radius: 	0.625rem;
$border-radius-lg: 	1.25rem;
$border-radius-sm: 	0.3125rem;

// Navs

$navbar-nav-link-padding-x: 	1rem;
$navbar-padding-y: 				2rem;
$navbar-padding-x: 				0;
$navbar-toggler-padding-y:		0;
$navbar-toggler-padding-x:		0;
$navbar-toggler-border-radius:	0;
$navbar-dark-toggler-icon-bg:	url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Crect width='40' height='40' fill='white'/%3E%3Cpath d='M7 29.5714H33V25.8571H7V29.5714ZM7 11V14.7143H33V11H7ZM7 22.1429H33V18.4286H7V22.1429Z' fill='%234268B0'/%3E%3C/svg%3E");
$nav-tabs-border-color:			$primary;
$nav-tabs-border-radius:        $border-radius-lg;
$nav-tabs-link-active-color:    $secondary;
$nav-tabs-link-active-bg:       #ffffff;
$nav-tabs-link-hover-border-color:  unset unset $nav-tabs-border-color;
$nav-tabs-link-active-border-color: unset unset $nav-tabs-border-color;

// Buttons + Forms

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      2.875rem;
$input-btn-font-size-lg:      1.5rem;
$input-btn-line-height:       1.25;

// Buttons

$btn-padding-y:     0.5rem;
$btn-padding-x:     1rem;
$btn-font-size: 	1.125rem;
$btn-border-radius:	20rem;
$btn-font-weight:	bold;

// Forms

$label-margin-bottom:		.75rem;
$input-padding-y: 			.5rem;
$input-padding-x: 			1rem;
$input-border-radius: 		$border-radius-sm;
$input-color:               $secondary;
$input-border-color:        #DDDDDD;
$input-group-addon-color:	$secondary;
$input-group-addon-bg:  	#DDDDDD;

// Breadcrumbs

$breadcrumb-font-size:		0.875rem;
$breadcrumb-item-padding:   1rem;
$breadcrumb-margin-bottom:	0;
$breadcrumb-bg:				$primary;
$breadcrumb-divider-color:  white;
$breadcrumb-active-color:	white;
$breadcrumb-border-radius:	0;

// Typography

$h1-font-size: 3rem;
$h2-font-size: 2rem;

$headings-font-weight:	700;
$headings-color: 		$secondary;

// Pagination

$pagination-padding-x:              .5rem;
$pagination-color:                  $secondary;
$pagination-border-color:           $secondary;

$pagination-hover-color:            $primary;
$pagination-hover-bg:               $light;
$pagination-hover-border-color:     $pagination-border-color;

$pagination-active-color:           white;
$pagination-active-bg:              $secondary;

$pagination-disabled-color:         $secondary;
$pagination-disabled-bg:            white;
$pagination-disabled-border-color:  $secondary;


// Cards

$card-border-color:	#DDD;